﻿import * as $ from "jquery";

import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/modal";
import uploadFile from "./reviewUploadFile.js";
import rate from "./ratings.js";
import { showDialog, showAlert } from "../global/dialog";
import { ajaxSend } from "../global/fetch";
import { getUrlParameter } from "../global/url";
import { getCookie } from "../global/cookie";
import "readmore-js";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { addEventDelegate } from "../global/dom";

import "../../css/modules/firm/reviews.css";
import { initPopovers } from "../global/popover.js";

var reviews = {
	options: {
		ajaxURL: {
			getComments: '/FirmRequests/GetReviewCommentsItems',
			getReviews: '/FirmRequests/GetFirmReviewItems',
			UpdateReviewVote: '/FirmRequests/UpdateReviewVote',
			SaveReview: '/FirmRequests/SaveReview',
			SaveReviewComment: '/FirmRequests/SaveReviewComment',
			updateComment: '/FirmRequests/UpdateReviewComment',
			deleteComment: '/FirmRequests/DeleteReviewComment',
			UpdateReview: '/firmrequests/updateReview',
			DeleteReview: '/reviews/DeleteReview'
		},
		userID: 0,
		canAccessReview: false,
		//isUserAddReview: false,
		isAuthenticated: false,
		coord: 0,
		isRateReview: false, //создаем отзыв с рейтинга
		callback: null
	},
	init: function (options) {
		this.options = $.extend(this.options, options);
		
		this.initCallbacks();

		if ($("#allCommentsBlock, #reviews").hasClass("show") && !this.isMob()){
			this.getReviews($("#allCommentsBlock, #reviews"))
			.then(() => {
				if (this.options.callback) this.options.callback();
			});
		}

		if (getUrlParameter('create-review') == '1') {
			if (this.options.isAuthenticated) {
				$('a[href="#creat-review-modal"]')[0]?.click();
			} else {
				showDialog({
					title: "Оставить отзыв",
					content: '<a href="/signup">Авторизуйтесь</a>, чтобы оставить отзыв.',
					onShown: () => $('#asModal .btn.btn-primary').remove()
				});
			}
		}

		uploadFile.init();
	},
	initCallbacks: function(){
		let self = this;

		$(document).on('hide.bs.modal', '#creat-review-modal', () => {
			var modal = $('#creat-review-modal');
			$(modal).find('.tum-item-name').text('');
			$('fieldset', modal).removeClass('error');
			$('fieldset textarea', modal).val('');
			$('.errMsg', modal).html('');
			$('.files-container').html('');
			$('.star-rating', modal).removeAttr('data-rating');
			$('.star-rating-stars .fa-star', modal).removeClass('fas tum-yellow').addClass('far tum-grey');
			reviews.options.isRateReview = false;
			if (reviews.isMob()) {
				$('fieldset textarea', modal).attr('rows', '7').css('heigt', 'auto');
			} else {
				$('fieldset textarea', modal).attr('rows', '4').removeAttr('style');
			}
		});

		$(document).on('show.bs.modal', '#creat-review-modal', function(e) {
			var btn = e.relatedTarget;
			var modal = $(this);
			if ($(btn).attr('id') != 'tum-review-modal') {
				var rating = Number($('#star-rating-widget').find('.tum-set-rate').attr('data-rate') || 0);
				if (rating > 0) {
					rate.updateRatings(rating, $('.star-rating', this));
					$('.star-rating', this).attr('data-rating', rating);
				}
				$(".tum-send-review-btn").attr("data-productid", $(btn).attr("data-productid"));
			}
			var itemName = $(btn).attr('data-itemname') || '';
			$(this).find('.tum-item-name').text(itemName.length ? 'для ' + itemName : '');
			reviews.options.isRateReview = ($(btn).attr('data-ispopup') || 0) == 1;
			if (reviews.isMob()) {
				$('fieldset textarea', modal).attr('rows', '7').css('height', 'auto');
			} else {
				$('fieldset textarea', modal).attr('rows', '4').removeAttr('style');
			}
			reviews.checkReviewsFields();
		});
		
		$(document).on('show.bs.collapse', '#allCommentsBlock, #reviews', function(e) {
			if ($(this).is(e.target)) {
				var i = $(this).prev().find('i.fas');
				reviews.changeCollapseIcon(i);
				$('.tum-readmore', this).css("height", "15px");
				$('.tum-readmore', this).readmore({
					moreLink: '<a class="tum-grey readMore" href="#" rel="nofollow"><small><i class="fas fa-chevron-down"></i></small></a>',
					lessLink: '<a class="tum-grey readLess" href="#" rel="nofollow"><small><i class="fas fa-chevron-up"></i></small></a>',
					speed: 100,
					collapsedHeight: 20
				});

				reviews.getReviews($(this));
			}
		});

		$(document).on('hide.bs.collapse', '#allCommentsBlock, #reviews', function(e) {
			if ($(this).is(e.target)) {
				var i = $(this).prev().find('i.fas');
				reviews.changeCollapseIcon(i);
				$('.tum-switch-visible', this).addClass('d-none');
				$('#reviewsContainer', this).html('');
				$('.tum-reviews-more').attr('data-page', 2);
			}

		});

		addEventDelegate(document, 'click', '.tum-show-comments, .tum-hide-comments', element => {
			reviews.toggleText($(element))
		});
		addEventDelegate(document, 'click', '.tum-reviews-more', element => {
			reviews.getReviews($(element));
		});
		addEventDelegate(document, 'click', '.tum-comments-more', element => {
			reviews.getComments($(element));
		});

		$(document).on('show.bs.collapse', '.tum-panel-review', function(e) {
			if ($(this).is(e.target)) {
				self.getComments2($(this));
			}
		});
		addEventDelegate(document, 'click', '.tum-review-rate a', (element, e) => {
			e.preventDefault();
		});
		addEventDelegate(document, 'click', '.tum-send-vote', (element, e) => {
			e.preventDefault();
			reviews.updateReviewVote($(element));
		});
		addEventDelegate(document, 'click', '.tum-send-review-btn', (element, e) => {
			e.preventDefault();
			reviews.addReview($(element), reviews.options.isRateReview);
		});
		addEventDelegate(document, 'click', '.tum-send-comment-btn', (element, e) => {
			e.preventDefault();
			reviews.addComment($(element));
		});
		addEventDelegate(document, 'keyup', '.tum-panel-review #tum-comment-text', element => {
			var count = $(element).val().length;
			$('.tum-comment-charCount', $(element).closest('.tum-panel-review')).text(count || 0);
		});
		addEventDelegate(document, 'input', '.tum-send-review-block #tum-review-text,.tum-send-review-block #tum-review-text-plus,.tum-send-review-block #tum-review-text-minus', element => {
			var count = $(element).val().length;
			$('+.tum-field-charCountBlock .tum-review-charCount', $(element).closest('.form-group-inline')).text(count || 0);
			reviews.checkReviewsFields();
		});
		//addEvent(document, 'click', '#tum-review-text', element => {
		//	reviews.checkReviewsFields();
		//});
		addEventDelegate(document, 'keydown', '#tum-comment-text', element => {
			$('fieldset', $(element).closest('.tum-panel-review')).removeClass('error');
			$('.tum-error-block', $(element).closest('.tum-panel-review')).hide();
		});
		addEventDelegate(document, 'keydown', '#tum-review-text', element => {
			$('fieldset', $(element).closest('.tum-send-review-block')).removeClass('error');
			$('.tum-error-block', $(element).closest('.tum-send-review-block')).hide();
		});
		
		
		addEventDelegate(document, 'click', ".tum-review-editComment, .tum-review-commentEditCancel", element => {
			$(element).closest(".tum-review-commentItem").find(".tum-review-commentEditBlock").toggleClass("d-none");
		});

		addEventDelegate(document, 'click', ".tum-review-commentEditSave", element => {
			var $comment = $(element).closest(".tum-review-commentItem");
			var commentID = $comment.attr("data-commentid");
			var commentText = $comment.find(".tum-review-commentEditText").val().trim();
			var denyPublish = $comment.find(".tum-review-denyCommentPublish").prop("checked");
			var pinned = $comment.find(".tum-review-pinComment").prop("checked");

			reviews.updateComment(commentID, commentText, denyPublish, pinned);
		});

		addEventDelegate(document, 'change', ".tum-review-denyCommentPublish", element => {
			var $comment = $(element).closest(".tum-review-commentItem");
			var commentID = $comment.attr("data-commentid");
			var denyPublish = $(element).prop("checked");

			reviews.updateComment(commentID, null, denyPublish);
		});

		addEventDelegate(document, 'change', ".tum-review-pinComment", element => {
			var $comment = $(element).closest(".tum-review-commentItem");
			var commentID = $comment.attr("data-commentid");
			var pinned = $(element).prop("checked");

			reviews.updateComment(commentID, null, null, pinned);
		});

		addEventDelegate(document, 'click', ".tum-review-deleteComment", element => {
			var $comment = $(element).closest(".tum-review-commentItem");
			var commentID = $comment.attr("data-commentid");

			reviews.deleteComment(commentID);
		});
		
		addEventDelegate(document, "click", ".tum-reviews-isPublished", (element, e) => {
			e.preventDefault();

			var reviewID = $(element).attr("data-reviewid");
			var isPublished = $(element).prop("checked");

			reviews.setPublish(reviewID, isPublished, $(element));
		});

		addEventDelegate(document, 'click', ".tum-review-deleteReview", element => {
			var id = $(element).closest(".tum-review-reviewItem").attr("data-id");
			reviews.deleteReview(id);
		});
	},
	isMob: function () {
		var res = false;
		if (window.innerWidth < 768) {
			res = true;
		}
		return res;
	},
	checkReviewsFields: function () {
		var isComment = $('#tum-review-text').val().trim().length > 0;
		var isPlus = $('#tum-review-text-plus').val().trim().length > 0;
		var isMinus = $('#tum-review-text-minus').val().trim().length > 0;
		var $btn = $('.tum-send-review-btn');
		if (!isComment && !isPlus && !isMinus) {
			$btn.attr('disabled', true).addClass('disabled');
		} else {
			$btn.removeAttr('disabled').removeClass('disabled');
		}
	},
	_getComments: function (el) {
		var reviewID = $(el).attr('data-itemid') || 0;
		var page = $(el).attr('data-page');
		var params = { reviewID: reviewID, page: page };

		return new Promise(resolve => {
			ajaxSend({ url: reviews.options.ajaxURL.getComments, data: params }).then(data => {
				$(el).attr('data-page', Number(page) + 1);
				var tmpl = getPrecompiledTemplate('reviews-comments');
				var htmlOut = tmpl(data);
				$('.card-body', $(el).closest('.tum-panel-review')).append(htmlOut);
				if ($('.card-body .tum-review-commentItem', $(el).closest('.tum-panel-review')).length > 0) {
					$('.card-body', $(el).closest('.tum-panel-review')).removeClass('p-0');
				}
	
				if (data.Total <= $(el).closest('.tum-panel-review').find('.card-body .panel').length) {
					$(el).closest('.tum-panel-review').find('.tum-comments-more').parent().addClass('d-none');
				} else {
					$(el).closest('.tum-panel-review').find('.tum-comments-more').parent().removeClass('d-none');
				}

				resolve(data);
			});
		});
	},
	getComments: function(el) {
		return loadPrecompiledTemplates([
			'reviews-comments',
			'reviews-upload-file',
		])
		.then(() => this._getComments(el));
	},
	_getComments2: function(el){ //вынесено из одного из $(document).on(...)
		var panel = el;
		var reviewID = el.attr('data-itemid');
		if (el.hasClass('loaded')) return Promise.resolve();

		return new Promise(resolve => {
			ajaxSend({ url: reviews.options.ajaxURL.getComments, data: { reviewID: reviewID, page: 1 } }).then(data => {
				var tmpl = getPrecompiledTemplate('reviews-comments');
				var htmlOut = tmpl(data);
				$('.card-body .spinner-load', panel).remove();
				$('.card-body', panel).append(htmlOut);
				if ($('.card-body .tum-review-commentItem', panel).length>0) {
					$('.card-body', panel).removeClass('p-0');
				}
	
				if (data.Total <= $(panel).find('.card-body .panel').length) {
					$('.tum-comments-more', panel).parent().addClass('d-none');
				} else {
					$('.tum-comments-more', panel).parent().removeClass('d-none');
				}
	
				el.addClass('loaded');
				resolve(data);
			});
		})
	},
	getComments2: function(el) {
		return loadPrecompiledTemplates([
			'reviews-comments',
			'reviews-upload-file',
		])
		.then(() => this._getComments2(el));
	},
	_getReviews: function (el) {
		if (!reviews.options.canAccessReview) { return Promise.resolve(); }

		var firmID = 0, productID = 0, reviewIDs = "";
		if ($(el).hasClass('tum-product-reviews') || $(el).closest('.tum-product-reviews').find('.tum-reviews-more').length > 0) {
			productID = $(el).attr('data-itemid') || 0;
		} else if ($(el).hasClass('tum-firm-reviews') || $(el).closest('.tum-firm-reviews').find('.tum-reviews-more').length > 0) {
			firmID = $(el).attr('data-itemid') || 0;
		} else if ($(el).hasClass('tum-other-reviews') || $(el).closest('.tum-other-reviews').find('.tum-reviews-more').length > 0) {
			reviewIDs = $(el).attr('data-itemid') || 0;
		}
		var page = $(el).attr('data-page') || 1;
		var params = { firmID: firmID, page: page, productID: productID, reviewIDs: reviewIDs };

		return new Promise(resolve => {
			ajaxSend({ url: reviews.options.ajaxURL.getReviews, data: params }).then(data => {

				var isBtnMore = $(el).hasClass('tum-reviews-more');
				if (isBtnMore) {
					$(el).attr('data-page', Number(page) + 1);
				}
				if ($('#tum-review-modal').hasClass('hide')) {
					$('#tum-review-modal').removeClass('hide');
				}
	
				var tmpl = getPrecompiledTemplate('reviews');
				var htmlOut = tmpl({ Items: data.Items, isAuthenticated: reviews.options.isAuthenticated, isEditView: getCookie("isEditView") == "1" });
				$('#reviewsContainer').append(htmlOut);

				if (data.Total <= $('#reviewsContainer > .card').length) {
					//$(el).closest('.row').find('.tum-switch-visible:not(.tum-auth-block)').addClass('d-none');//remove();
					var btnMore = $(el).closest('.row').find('.tum-switch-visible .tum-reviews-more');
					$(btnMore).closest('div').addClass('d-none');//remove();
					$(el).closest('.row').find('.tum-switch-visible .tum-reviews-more').attr('data-page', 2);//remove();
				}
				
				$(document).ajaxComplete(function () {
					initPopovers();
				});

				if (!isBtnMore) {
					$('.tum-switch-visible', el).removeClass('d-none');
				}

				resolve(data);
			});
		});
	},
	getReviews: function (el) {
		return loadPrecompiledTemplates(['reviews'])
		.then(() => this._getReviews(el));
	},
	toggleText: function (el) {
		if ($(el).hasClass('tum-show-comments')) {
			$(el).closest('.card-header').find('.review-text').toggleClass('tum-review-text');

			$(el).toggleClass('d-none');
			var hide = $(el).closest('.card-header').find('.tum-hide-comments, .tum-review-open');
			$(hide).toggleClass('d-none');
			$(el).closest('.card-header').find('.tum-review-files').removeClass('d-none');
		} else if ($(el).hasClass('tum-hide-comments')) {
			$(el).closest('.card-header').find('.review-text').toggleClass('tum-review-text');

			$(el).toggleClass('d-none');
			$(el).closest(".card-header").find(".tum-review-open").toggleClass("d-none");
			var show = $(el).closest('.card-header').find('.tum-show-comments');
			$(show).toggleClass('d-none');
			//console.log($(el).closest('.card-header').find('.tum-review-files'))
			$(el).closest('.card-header').find('.tum-review-files').addClass('d-none');
		}
	},
	updateReviewVote: function (el) {
		var code = $(el).attr('data-code') || 0;
		var id = $(el).attr('data-itemid');
		var params = { reviewID: id, code: code };

		var elNegative = $(el).closest('.tum-review-rate').find('#negative-vote').closest('a');
		var elPositive = $(el).closest('.tum-review-rate').find('#positive-vote').closest('a');

		if ($(el).hasClass('review-selected')) {
			return;
		}
		ajaxSend({ url: reviews.options.ajaxURL.UpdateReviewVote, data: params }).then(data => {
			if (data.result) {
				var response = data.Items;
				var dataRateUp = Number(response.ratingUp);
				var dataRateDown = Number(response.ratingDown);

				if (response.rate == 'up' && response.userRateUp == 1) {
					$(el).addClass('review-selected');
					$(el).closest('.tum-review-rate').find('#positive-vote').addClass('positive-vote');
					$(el).closest('a').addClass('tum-user-prate');
					$(el).closest('.tum-review-rate').find('span.rateUp').text(dataRateUp);

					$(el).closest('.tum-review-rate').find('#negative-vote').removeClass('negative-vote');

					$(elNegative).popover('disable');
					$(elNegative).removeClass('review-selected');

					if (!$(el).attr('data-content')) {
						$(el).attr({
							'data-toggle': 'popover',
							'data-placement': 'top',
							'data-html': 'true',
							'data-content': 'Вы уже поставили свой рейтинг'
						});
					}
					$(el).popover('enable');
					$(el).closest('.tum-review-rate').find('a.tum-user-nrate').removeClass('tum-user-nrate');
					$(el).closest('.tum-review-rate').find('span.rateDown').text(dataRateDown);
				} else if (response.rate == 'down' && response.userRateUp == 0) {
					$(el).addClass('review-selected');
					$(el).closest('.tum-review-rate').find('#positive-vote').removeClass('positive-vote');

					$(elPositive).popover('disable');
					$(elPositive).removeClass('review-selected');

					if (!$(el).attr('data-content')) {
						$(el).attr({
							'data-toggle': 'popover',
							'data-placement': 'top',
							'data-html': 'true',
							'data-content': 'Вы уже поставили свой рейтинг'
						});
					}
					$(el).popover('enable');

					$(el).closest('.tum-review-rate').find('a.tum-user-prate').removeClass('tum-user-prate');
					$(el).closest('.tum-review-rate').find('span.rateUp').text(dataRateUp);

					$(el).closest('.tum-review-rate').find('#negative-vote').addClass('negative-vote');
					$(el).closest('a').addClass('tum-user-nrate');
					$(el).closest('.tum-review-rate').find('span.rateDown').text(dataRateDown);
				} else if (response.isFirmAgent) {
					$(elNegative).addClass('review-selected');
					$(elPositive).addClass('review-selected');
					$(elNegative).attr({
						'data-toggle': 'popover',
						'data-placement': 'top',
						'data-html': 'true',
						'data-content': response.msg
					});
					$(elPositive).attr({
						'data-toggle': 'popover',
						'data-placement': 'top',
						'data-html': 'true',
						'data-content': response.msg
					});

					initPopovers();
					
					$(el).popover('show');
				}
			}
		})
	},
	addComment: function (el) {
		var reviewID = $(el).closest('.tum-panel-review').attr('data-itemid') || 0;
		var comment = $(el).closest('.tum-panel-review').find('#tum-comment-text');
		var commentBlock = $(el).closest('.tum-panel-review');
		if (!comment.val() || comment.val().trim().length < 15) {
			reviews.showError("Минимальная длина комментария должна быть 15 символов", $('.error-block-text', commentBlock), $('#tum-comment-text'));
			$(comment).focus();
			return;
		}
		if (comment.val().trim().length > 512) {
			reviews.showError("Слишком длинный комментарий", $('.error-block-text', commentBlock), $('#tum-comment-text'));
			$(comment).focus();
			return;
		}
		$(el).prop("disabled", true);
		var params = { 
			reviewID: reviewID, 
			commentText: comment.val().trim(), 
			files: $('.img-file-name', commentBlock).toArray().map(e => $(e).attr('data-url'))
		};
		ajaxSend({ url: reviews.options.ajaxURL.SaveReviewComment, data: params }).then(data => {
			if (data.result) {
				var tmpl = getPrecompiledTemplate('reviews-comments');
				var htmlOut = tmpl(data.data);
				$('.card-body', $(".tum-panel-review[data-itemid=" + reviewID + "]")).append(htmlOut);
				if ($('.card-body .tum-review-commentItem', $(".tum-panel-review[data-itemid=" + reviewID + "]")).length > 0) {
					$('.card-body', $(".tum-panel-review[data-itemid=" + reviewID + "]")).removeClass('p-0');
				}
				comment.val('');
				$('.file-cont', commentBlock).remove();
			}
			else {
				showAlert("Ошибка сохранения комментария", { type: 'danger' });
			}
			$(el).prop("disabled", false);
		}, false, $('.tum-send-comment-btn'));
	},
	updateComment: function(commentID, commentText, denyPublish, pinned) {
		ajaxSend({ url: reviews.options.ajaxURL.updateComment, data: { commentID: commentID, commentText: commentText, denyPublish: denyPublish, pinned: pinned } }).then(data => {
			if (data.result) {
				if (commentText) {
					var $comment = $(".tum-review-commentItem[data-commentid=" + commentID + "]");
					$comment.find(".tum-review-commentText").html(commentText);
					$comment.find(".tum-review-commentEditBlock").addClass("d-none");
				}
			}
			else {
				showAlert("Ошибка сохранения комментария", { type: 'danger' });
			}
		});
	},
	deleteComment: function(commentID) {
		if (!confirm("Вы уверены, что хотите удалить комментарий?")) return;

		ajaxSend({ url: reviews.options.ajaxURL.deleteComment, data: { commentID: commentID } }).then(data => {
			if (data.result) {
				$(".tum-review-commentItem[data-commentid=" + commentID + "]").remove();
			}
			else {
				showAlert("Ошибка сохранения комментария", { type: 'danger' });
			}
		});
	},
	addReview: function (el, isPopup) {
		var firmID = $('#tum-review-modal').attr('data-firmid') || $(el).attr("data-firmid") || 0;
		var productID = $('#tum-review-modal').attr('data-productid') || $(el).attr("data-productid") || 0;
		var ctuID = $('#tum-review-modal').attr('data-ctuid') || 0;
		var review = $(el).closest('#creat-review-modal').find('#tum-review-text');
		
		var minus = $(el).closest('#creat-review-modal').find('#tum-review-text-minus');
		var plus = $(el).closest('#creat-review-modal').find('#tum-review-text-plus');
 
		var orderID = $(el).attr("data-orderid") || 0;
		var isSeller = $(el).attr("data-isseller") == "1";

		var mark = $('.tum-set-rating .star-rating').attr('data-rating') || 0;

		var urls = '';
		if ($('.files-container .upload-filename').length) {
			var urlArr = [];
			$.each($('.files-container .upload-filename'), function (i, el) {
				urlArr.push($(el).attr('data-url'));
			});
			urls = urlArr.join(',');
		}
		
		if (+mark <= 0) {
			$('.underFieldMsg', $('.error-block-rating')).html('<i class="fa fa-exclamation-circle" aria-hidden="true"></i> Не указана оценка');
			$('.error-block-rating').show();
			$('.underFieldMsg', $('.error-block-rating')).addClass('errMsg');
			$('.tum-review-setRatingLabel').focus();

			return;
		}
		
        if (review.val().trim().length < 15 && plus.val().trim().length < 15 && minus.val().trim().length < 15) {
			reviews.showError("Минимальная длина отзыва должна быть 15 символов", $('.error-block-text'));
			if (review.val().trim().length < 15) {
				$(review).focus();
			}
			
			return;
        }

        if (review.val().trim().length > 1024 || plus.val().trim().length > 1024 || minus.val().trim().length > 1024) {
			reviews.showError("Слишком длинный отзыв", $('.error-block-text'));
			return;
		}
		
		var params = {
			firmID: firmID,
			reviewText: review.val().trim(),
			productID: productID,
			ctuID: ctuID,
			reviewMinus: $(minus).val() || '',
			reviewPlus: $(plus).val() || '',
			mark: mark,
			orderID: orderID,
			isSeller: isSeller,
			urls: urls
		};

		$(el).prop("disabled", true);
		ajaxSend({ url: reviews.options.ajaxURL.SaveReview, data: params }).then(data => {
			if (data.result) {
				if (!isPopup) {
					var tmpl = getPrecompiledTemplate('reviews');
					var htmlOut = tmpl({ Items: data.Items, isAuthenticated: reviews.options.isAuthenticated, isEditView: getCookie("isEditView") == "1" });
					$('#reviewsContainer').append(htmlOut);

					$(document).ajaxComplete(function () {
						initPopovers();
					});
				}
				$('#creat-review-modal').modal('hide');
				//очищаем всплывашку для рейтинга, чтобы отработал ajax при следующем открытии 
				$('.popup-container').html('');

				$(".reviewsCount").text(+$(".reviewsCount").text() + 1);
				if ($(".tum-review-count").length > 0) {
					$('.tum-review-count').html('<i class="far fa-file"></i> ' + (+$(".tum-review-count").text() + 1));
				}

				if ($(el).hasClass("reloadOnSuccess")) {
					location.reload();
				}
			}
			else {
				showAlert("Ошибка сохранения отзыва", {type:'danger'});
			}
			$(el).prop("disabled", false);
		}, false, $('.tum-send-review-btn'));
	},
	showError: function (txt, errorBlock, el) {
		$('.underFieldMsg', errorBlock).html('<i class="fa fa-exclamation-circle" aria-hidden="true"></i> ' + txt);
		$(errorBlock).show();
        $('.underFieldMsg', errorBlock).addClass('errMsg');

        if (el) {
            $('fieldset', el.closest('.form-group-inline')).addClass('error');
            $('.tum-error-block', el.closest('.form-group-inline')).removeClass('hintBorder');
        }

	},
	changeCollapseIcon: function (i) {
		if ($(i).hasClass('fa-angle-down')) {
			$(i).removeClass('fa-angle-down');
			$(i).addClass('fa-angle-up');
		} else {
			$(i).removeClass('fa-angle-up');
			$(i).addClass('fa-angle-down');
		}
	},
	setPublish: function(reviewID, isPublished, $checkbox) {
		var params = {
			reviewID: reviewID,
			denyPublish: !isPublished,
			publishOnly: true,
			firmID: 0,
			productID: 0,
			ctuID: 0,
			reviewText: "",
			reviewMinus: "",
			reviewPlus: ""
		};

		ajaxSend({ url: reviews.options.ajaxURL.UpdateReview, data: params }).then(data => {
			if (data.result) {
				if ($checkbox) {
					$checkbox.prop("checked", !$checkbox.prop("checked"))
				}
			}
			else {
				showAlert("Ошибка при редактировании отзыва", { type: "danger" });
			}
		});
	},
	deleteReview: function(id) {
		if (!confirm("Вы уверены, что хотите удалить отзыв?")) {
			return;
		}

		ajaxSend({ url: reviews.options.ajaxURL.DeleteReview, data: { id: id } }).then(data => {
			if (data.result) {
				$(".tum-review-reviewItem[data-id=" + id + "]").remove();
			}
			else {
				showAlert("Ошибка при удалении отзыва", { type: "danger" });
			}
		});
	}
}

var rev = {
	options: {
		firmID: 0,
		tuID: 0,
		Total: 0
	},
	init: function (options) {
		rev.options = $.extend(rev.options, options);
		if (rev.options.Total > 0 && rev.options.Total > $('#reviewsContainer > .card').length) {
			var btnMore = $('.tum-reviews-show-more');
			$(btnMore).removeClass('d-none');
		}
		addEventDelegate(document, 'click', '.resetFilterLink', (element, e) => {
			e.preventDefault();
			$("#firmSelect").val(0);
			$("#ctuSelect").val(0);
			$("#tuSelect").val(0);
			$("#isPublishedSelect").val(0);
			$("#textFilter").val("");
			$("#orderID").val("");
			$(".chosen-select").trigger("change");
			$(".filterLink")[0]?.click();
		});
		addEventDelegate(document, 'click', '.filterLink', (element, e) => {
			e.preventDefault();
			rev.options.tuID = 0;

			var params = rev.getParams();
			params.page = 1;

			rev.getReviews(params);
		})

		if ($('.chosen-select').length) {
			import("select2").then(() => {
				return import("select2/dist/js/i18n/ru.js")
			}).then(() => {
				$('.chosen-select').select2({
					'minimumInputLength': 0,
					'language': "ru",
					dropdownAutoWidth: true,
					width: "100%"
				});
			});
		}

		$(document).on('change', "#firmSelect", function() {
			var firmID = +$(this).val();
			rev.updateFilterByFirm(firmID);
		});

		$('.tum-review-filter-block').css('visibility', 'visible');

		addEventDelegate(document, 'click', '.tum-reviews-show-more', () => {
			var params = rev.getParams();
			params.isPagination = true;

			rev.getReviews(params);
		});

		if (+rev.getURLParameter('firmID') > 0) {
			rev.updateFilter();
		}

		$(document).on('hidden.bs.collapse shown.bs.collapse', "#tum-reviews-filters", () => {
			$(".tum-reviews-showFilters").find(".fa-chevron-down, .fa-chevron-up").toggleClass("fa-chevron-down fa-chevron-up");
		});

		if ($('.isEditView').length > 0) {
			rev.isEditMode();
		}

	},
	updateFilter: function () {
		rev.options.tuID = +rev.getURLParameter('productID') || 0;
		rev.options.firmID = +rev.getURLParameter('firmID') || 0;

		$("#firmSelect option[value=" + rev.options.firmID + "]").prop("selected", true);
		$(".chosen-select").trigger("change");
		rev.updateFilterByFirm(rev.options.firmID);
	},
	updateFilterByFirm: function (firmID) {
		ajaxSend({ url: '/Reviews/GetFilterDataByFirm', data: { firmID: firmID } }).then(data => {
			$("#ctuSelect").html('<option value="0">Не выбрано</option>');
			$("#ctuSelect").append(data.ctuSource.map(e => "<option value='" + e.value + "'>" + e.text + "</option>").join(""));

			$("#tuSelect").html('<option value="0">Не выбрано</option>');
			$("#tuSelect").append(data.tuSource.map(e => "<option value='" + e.value + "'>" + e.text + "</option>").join(""));
			$("#tuSelect option[value=" + rev.options.tuID + "]").prop("selected", true);
			$(".chosen-select").trigger("chosen:updated");
		});
	},
	getParams: function () {
		return {
			page: $(".tum-reviews-show-more").attr("data-page") || 1,
			firmID: $('#firmSelect').val() || 0,
			productID: $('#tuSelect').val() || 0,
			ctuID: $('#ctuSelect').val() || 0,
			includeUnpublished: getCookie("isEditView") == "1",
			isPublished: $('#isPublishedSelect').val() || 0,
			textFilter: $("#textFilter").val() || "",
			orderID: $("#orderID").val() || 0,
		};
	},
	_getReviews: function (params) {
		var $btnMore = $('.tum-reviews-show-more');
		ajaxSend({ url: '/Reviews/GetFirmReviewItems', data: params }).then(data => {
			if ($btnMore.length) {
				$btnMore.attr('data-page', Number(params.page) + 1);
			}

			var tmpl = getPrecompiledTemplate('reviews');
			
			var htmlOut = tmpl({ 
				Items: data.Items, 
				isAuthenticated: reviews.options.isAuthenticated, 
				isEditView: getCookie("isEditView") == "1"
			});
			
			if (params.isPagination) {
				$('#reviewsContainer').append(htmlOut);
			}
			else {
				$('#reviewsContainer').html(htmlOut);
			}

			if (data.Total <= $('#reviewsContainer > .card').length) {
				$btnMore.closest('div').addClass('d-none');
				$btnMore.attr('data-page', 2);
			} else {
				$btnMore.closest('div').removeClass('d-none');
			}

			if (!$btnMore.length) {
				$('.tum-switch-visible').removeClass('d-none');
			}

			if (data.FilterInfo) {
				$('.filter-total-info').html(data.FilterInfo);
			}

			if (data.isFiltered) {
				$(".resetFilterLink").addClass("isFilteredBtn");
			}
			else {
				$(".resetFilterLink").removeClass("isFilteredBtn");
			}
		});
	},
	getReviews: function(params){
		loadPrecompiledTemplates(['reviews']).then(() => this._getReviews(params));
	},
	getURLParameter: function (sParam) {
		return getUrlParameter(sParam);
	},
	isEditMode: function () {
		var cookieValue = getCookie("isEditView");
		if (cookieValue == 1) {
			$('.isEditView').removeClass('tum-edit-set').addClass('tum-edit-unset');
			$('.isEditView i').removeClass('fa-toggle-off').addClass('fa-toggle-on');
		} else {
			$('.isEditView').removeClass('tum-edit-unset').addClass('tum-edit-set');
			$('.isEditView i').removeClass('tum-edit-on').addClass('fa-toggle-off');
		}
	}
};

export { reviews, rev };